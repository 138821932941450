@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@angular/material' as mat;
@include spb.set-typography(mat.m2-define-typography-config(
  $font-family: 'Titillium Web',
  $headline-1: mat.m2-define-typography-level(40px, 112px, 400, $letter-spacing: -0.05em), // CSS class .mat-headline-1
  $headline-2: mat.m2-define-typography-level(30px, 56px, 400, $letter-spacing: -0.02em), // CSS class .mat-headline-2
  $headline-3: mat.m2-define-typography-level(20px, 48px, 400, $letter-spacing: -0.005em), // CSS class .mat-headline-3
  $headline-4: mat.m2-define-typography-level(10px, 40px, 400), // CSS class .mat-headline-4
  $headline-5: mat.m2-define-typography-level(36px, 54px, 600), // h1
  $headline-6: mat.m2-define-typography-level(28px, 42px, 600), // h2 AND MatDialog Title
  $subtitle-1: mat.m2-define-typography-level(20px, 30px, 500), // h3
  $body-1: mat.m2-define-typography-level(18px, 24px, 400), // h4
  $body-2: mat.m2-define-typography-level(16px, 22px, 400), // body text
  $subtitle-2: mat.m2-define-typography-level(18px, 21px, 400), // CSS class .mat-subtitle-2
  $caption: mat.m2-define-typography-level(14px, 18px, 400), // CSS class .mat-caption
  $button: mat.m2-define-typography-level(14px, 22px, 600, 'Titillium Web') // bolder body
));


body, html {
  margin: 0;
}

a, a:visited {
  color: #232323;
}

th {
  font-size: 80%;
  color: #808080;
}

.spb-no-flippers::-webkit-outer-spin-button,
.spb-no-flippers::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}